<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">员工列表</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
                    <el-form-item >
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.username" placeholder="请输入员工姓名" clearable></el-input>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-input  :maxlength="11" style="width:300px;" v-model="seachForm.phone" placeholder="请输入手机号"  clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div class="down_box">
                <p @click="downLoad">下载文件模板</p>
                <el-upload
                    :headers="headers"
                    action="api/platform/excel/export"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess" 
					:before-upload="beforeUpload"
                    multiple
					type="primary"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    :file-list="fileList">
                    <el-button type="primary">员工导入</el-button>
                </el-upload>
            </div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="username" label="员工姓名" ></el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="jobNumber" label="工号" ></el-table-column>
				<el-table-column prop="email" label="邮箱" ></el-table-column>
				<el-table-column prop="createTime" label="注册时间" ></el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							编辑
						</el-button>
                        <el-button @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							移除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>


        <!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="600px" :before-close="handleClose" title="编辑">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm" size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="width:120px;text-align:right;"><span style="color:red">*</span>员工姓名：</p>
							<el-input type="text" v-model="sizeForm.username" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="width:120px;text-align:right;"><span style="color:red">*</span>手机号：</p>
							<el-input type="text" v-model="sizeForm.phone" oninput="if(value.length>11)value=value.slice(0,11)" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="width:120px;text-align:right;">密码：</p>
							<el-input type="text" v-model="sizeForm.password" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="width:120px;text-align:right;">工号：</p>
							<el-input type="text" v-model="sizeForm.jobNumber" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="width:120px;text-align:right;">邮箱：</p>
							<el-input type="text" v-model="sizeForm.email" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;align-item:center;justify-content:center;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button type="primary" @click.native.prevent="onUpdata('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		employeeList,employeeEditUpdate,employeeUndock,excelExport
	} from "@/api/platform/index";
	export default {
		name: "employeeList",
		data() {
			return {
                headers: {
					Authorization: this.$store.state.user.Token
				},
                seachForm:{
					phone: '',
					username: '',
				},
                fileList:[],
                sizeForm:{
                    id:'',
                    username:'',
                    phone:'',
                    password:'',
                    jobNumber:'',
                    email:''
                },
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addshow: false,

			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				employeeList({
                    phone:this.seachForm.phone,
                    username:this.seachForm.username,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

            //编辑按钮点击
			updataclick(i,tab){
				this.sizeForm = {
					id: tab[i].id,
					username:tab[i].username,
					phone: tab[i].phone,
					password: tab[i].password,
					jobNumber: tab[i].jobNumber,
					email: tab[i].email,
				}
				this.addshow = true
			},

			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id:'',
					username:'',
					phone:'',
					password:'',
					jobNumber:'',
					email:''
				}
				this.addshow = false
			},

             //编辑提交
			onUpdata(formName){
				let that = this;
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
							if(that.$refs[formName].model.username != ''){
								if(that.$refs[formName].model.phone != ''){
								    if(that.$refs[formName].model.password  != null && that.$refs[formName].model.password.length > 0){
										if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.$refs[formName].model.phone))){
											that.$message.warning('手机号不合法');
											return;
										}
                                        employeeEditUpdate({
                                            id:that.$refs[formName].model.id,
                                            username:that.$refs[formName].model.username,
                                            phone:that.$refs[formName].model.phone,
                                            password:that.$refs[formName].model.password,
                                            jobNumber:that.$refs[formName].model.jobNumber,
                                            email:that.$refs[formName].model.email
                                        }).then(res=>{
                                            if (res.code == 200) {
                                                that.$message.success('编辑成功');
                                                that.sizeForm = {
                                                    jobNumber:'',
                                                    email:'',
                                                    password: '',
                                                    phone: '',
                                                    username: '',
                                                    id:''
                                                }
                                                that.addshow = false;
                                                that.page = 1
                                                that.list();
                                            } else {
                                                that.$message.error(res.msg);
                                            }
                                        }).catch(err => {
                                            that.$message.error(err.msg);
                                        })
                                    }else{
									    that.$message.warning('密码不能为空');
								    }
								}else{
									that.$message.warning('手机号不能为空');
								}
							}else{
								that.$message.warning('员工名称不能为空');
							}
						} else {
							return false;
						}
					});
				}
			},

			//冻结/恢复/取消入驻
			deleteList(cur, tab) {
				this.$confirm('你确定要移除员工【' + tab[cur].username + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					employeeUndock(tab[cur].id ).then(res => {
						if (res.code == 200) {
							this.$message.success('操作成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				})
			},

            // 下载员工列表模板
            downLoad(){
				let that = this;
                that.$axios({
                    method:"get", 
                    url:"/api/platform/excel/download", 
                    headers:{
                        Authorization: that.$store.state.user.Token,
                    },
                    responseType:'blob'
                }).then((res) => { 
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute(
                        'download',
                        "员工列表文件模板.xlsx"
                    )
                    document.body.appendChild(link)
                    link.click()
                })
            },

            // 文件校验
            beforeUpload(file) {
				// 文件类型
				const isType = file.type === 'application/vnd.ms-excel'
				const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				const fileType = isType || isTypeComputer
				if (!fileType) {
					this.$message.error('上传文件只能是xls/xlsx格式！')
				}
				// 文件大小限制为2M
				const fileLimit = file.size / 1024 / 1024 < 2
				if (!fileLimit) {
					this.$message.error('上传文件大小不超过2M！')
				}
				return fileType && fileLimit
			},

            // 导入成功
            handleAvatarSuccess(res, file) {
				if(res.code == 200){
					this.$message.success(res.msg);
                    this.page = 1;
                    this.list();
                }else{
					this.$message.error(res.msg);
				}
			},
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.down_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.down_box p{
    font-size: 14px;
    color: blue;
    cursor: pointer;
    margin-right: 20px;
}

</style>
